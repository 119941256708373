import React from "react";

import cn from "classnames";

import { SelectionIndicator, SelectionType } from "components/SelectionIndicator";

import { AnswerOption, AnswerOptionProps } from "./AnswerOption";

import styles from "./AnswerOption.module.scss";

type Props = {
  type: SelectionType;
  checked: boolean;
  dataKey?: string | number;
} & AnswerOptionProps;

export const SelectionOption = ({ type, checked, disabled, option, className, ...rest }: Props) => {
  return (
    <AnswerOption
      option={<SelectionIndicator type={type} checked={checked} disabled={disabled} label={option} />}
      className={cn({ [styles.selected]: checked }, { [styles.disabled]: disabled }, className)}
      {...rest}
    />
  );
};
